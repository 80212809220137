import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: process.env.VUE_APP_API_URL,
    login: false,
    token: '',
    email: '',
    darkmode:false,
    aside:false,
    walletLoginPp: false,
  },
  plugins: [createPersistedState()],
  mutations: {
    SETLOGIN: (state, mem_info) => {
      state.login = true;
      state.token = mem_info.token;
      state.email = mem_info.email;
    },
    SETLOGOUT:(state) => {
      state.login = false;
      state.token = '';
      state.email = '';
    },
    darkmodeFn:(state, payload)=>{
      state.darkmode = payload;
    },
    asideFn:(state, payload) => {
      state.aside = payload
    },
    walletLoginPpFn:(state, payload) => {
      state.walletLoginPp = payload
    }
  },
  actions: {
    SETLOGIN ({commit}, mem_info) {
      commit('SETLOGIN', mem_info);
    },
    SETLOGOUT({commit}) {
      commit('SETLOGOUT');
    }
  },
  modules: {
  }
})
