<template>
    <div class="aside_menu" :class="{'open':$store.state.aside}">
        <ul class="menu_list">
            <li class="menu_item">
                <router-link to="/main">
                    <span>
                        <i class="far fa-home-alt"></i>
                        대시보드
                    </span>
                </router-link>
            </li>
            <li class="menu_item">
                <router-link to="/game">
                    <span>
                        <i class="far fa-gamepad"></i>
                        게임
                    </span>
                </router-link>
            </li>
            <li class="menu_item">
                <router-link to="/launchpad">
                    <span>
                        <i class="far fa-rocket"></i>
                        런치패드
                    </span>
                </router-link>
            </li>
            <li class="menu_item">
                <router-link to="/nft">
                    <span>
                        <i class="far fa-thumbtack"></i>
                        NFT 마켓
                    </span>
                </router-link>
            </li>
            <li class="menu_item">
                <router-link to="/farm">
                    <span>
                        <i class="far fa-shovel"></i>
                        FARM(스테이킹)
                    </span>
                </router-link>
            </li>
            <li class="menu_item">
                <a href="#" class="d-flex justify-content-between" @click="menuToggle.nfts=!menuToggle.nfts">
                    <span>
                        <i class="far fa-bags-shopping"></i>
                        NFTs
                    </span>
                    <i class="fs-6 far" :class="{'fa-chevron-down':!menuToggle.nfts,'fa-chevron-up':menuToggle.nfts}"></i>
                </a>
                <ul v-show="menuToggle.nfts">
                    <li class="menu_item">
                        <router-link to="/mining">NFT MINING</router-link>
                    </li>
                    
                    <li class="menu_item">
                        <router-link to="/nft-box">NFT BOX</router-link>
                    </li>
                    
                    <li class="menu_item">
                        <router-link to="/minting">NFT MINTING</router-link>
                    </li>
                </ul>
            </li>
            <li class="menu_item">
                <router-link to="/about">
                    <span>
                        <i class="far fa-info"></i>
                        사이트 소개
                    </span>
                </router-link>
            </li>
            <li class="menu_item">
                <router-link to="/community">
                    <span>
                        <i class="far fa-comment-alt"></i>
                        커뮤니티
                    </span>
                </router-link>
            </li>
            <li class="menu_item">
                <router-link to="/friends">
                    <span>
                        <i class="far fa-handshake-alt"></i>
                        제휴사/협력사
                    </span>
                </router-link>
            </li>
            <li class="menu_item">
                
                <a href="#" class="d-flex justify-content-between" @click="menuToggle.cs=!menuToggle.cs">
                    <span>
                        <i class="far fa-asterisk"></i>
                        고객센터
                    </span>
                    <i class="fs-6 far" :class="{'fa-chevron-down':!menuToggle.cs,'fa-chevron-up':menuToggle.cs}"></i>
                </a>
                <ul v-show="menuToggle.cs">
                    <li class="menu_item">
                        <router-link to="/cs/notice">공지사항</router-link>
                    </li>
                    
                    <li class="menu_item">
                        <router-link to="/cs/faq">FAQ</router-link>
                    </li>
                    
                    <li class="menu_item">
                        <router-link to="/cs/inquiry">1:1 문의</router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data(){
        return{
            menuToggle:{nfts:false,cs:false},
        }
    }
}
</script>

<style lang="scss" scoped>
    .aside_menu{
        width: 260px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        height: 100vh;
        padding-top: 68px;
        background-color: #fff;
        z-index: 998;
        position: fixed;
    }

    .menu_list{
        .menu_item{
            margin-top: 30px;
            text-align: left;

            a{
                margin: 0 10px 0 25px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                color: #2f2e41;
                position: relative;
                user-select: none;

                &::before{
                    content: "";
                    position: absolute;
                    left: -25px;
                    background: #541792;
                    width: 3px;
                    top: 0;
                    opacity: 0;
                    height: 100%;
                    transition: opacity .2s ease,transform .2s ease;
                }

                .far{
                    margin-right: 10px;
                    font-size: 20px;
                    text-align: center;
                    width: 35px;
                }
            }
            .router-link-exact-active{
                color: #541792;

                &::before{
                    opacity: 1;
                }
            }
        }
    }
    

    @media screen and (max-width:1279px) {
        .menu_list .menu_item a{
            font-size: 22px;
        }
    }
    @media screen and (max-width:768px) {
        .menu_list .menu_item a{
            font-size: 18px;
        }
    }
</style>