<template>
    <div id="Header">
        <img src="@/assets/img/logo.jpg" alt="logo" @click="$router.push('/main')" class="logo" :class="{'dark':theme}">

        <div class="d-flex align-items-center" v-if="windowWidth>1279">
            <ul id="nav" class="d-flex justify-content-between">
                <li><a href="#"><i class="fas fa-download me-2"></i> App Download</a></li>
                <li><a href="#">Swap</a></li>
                <li class="filled" @click="$router.push({name: 'Login'})" v-if="!login">로그인</li>
                <li class="filled" @click="Logout" v-else>Logout</li>
            </ul>
            <div class="lang ms-3">
                <div v-popover:po_lang class="me-3">
                    <span class="cursor_pointer tit">{{langList[now_lang]}} <i class="fas fa-sort-down"></i></span>
                    <popover name="po_lang">
                        <ul>
                            <li v-for="(item,index) in langList" 
                            @click="now_lang=index"
                            :class="{'text-main':now_lang==index}"
                            :key="index">
                                {{item}}
                            </li>
                        </ul>
                    </popover>    
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center" v-else>
            <span class="badge bg-primary rounded-pill text-white border-0 mobile-connect mx-2">
                <li class="filled" @click="$router.push({name : 'Login'})" v-if="!login">로그인</li>
                <li class="filled" @click="Logout" v-else>Logout</li>
            </span>
            <!-- <span class="badge bg-primary rounded-pill text-white border-0 mobile-connect" @click="Logout" v-else><a href="#">Logout</a></span> -->
            <div class="lang mx-3">
                <div v-popover:po_lang2 class="me-3">
                    <span class="cursor_pointer tit d-flex align-items-center">{{langList[now_lang]}} <i class="fas fa-sort-down ms-2"></i></span>
                    <popover name="po_lang2">
                        <ul>
                            <li v-for="(item,index) in langList" 
                            @click="now_lang=index"
                            :class="{'text-main':now_lang==index}"
                            :key="index">
                                {{item}}
                            </li>
                        </ul>
                    </popover>    
                </div>
            </div>
            <button class="btn menu p-0 icon" @click="openFn()"><b-icon-list></b-icon-list></button>
        </div>
    </div>
</template>

<script>
import { BIconList } from 'bootstrap-vue'
export default {
    components:{
        BIconList
    },
    data(){
        return{
            login: this.$store.state.login,
            theme:this.$store.state.darkmode,
            windowWidth:window.innerWidth,
            openMobileMenu:this.$store.state.aside,
            langList: ['KR','EN','JP','CN'],
            now_lang: 0

        }
    },
    watch: {
        $route(to, from) {
            if (to.path != from.path) {
                this.openMobileMenu= false;
                this.$store.commit('asideFn',this.openMobileMenu)
                this.login = this.$store.state.login;
            } 
        } 
    },
    mounted(){
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    methods:{
        Logout(){                           
            this.$store.dispatch('SETLOGOUT').then(
                ()=>{
                this.$router.go(0);
            });
        },
        onResize() {
            this.windowWidth = window.innerWidth
            if(this.windowWidth>768){
                this.openMobileMenu= false;
            }
        },
        openFn(){
            this.openMobileMenu = !this.$store.state.aside;
            this.$store.commit('asideFn',this.openMobileMenu)
        }
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
}
</script>

<style lang="scss" scoped>
#Header{
    position: fixed;
    top: 0;
    width:100%;
    background-color: #fff;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    padding: 0 30px;
    z-index: 999;
    .logo{
        height: 30px;
        cursor: pointer;
    }
    #nav{
        text-transform: uppercase;
        li{
            color: #541792;
            border: 1px solid #541792;
            background: none;
            font-size: 13px;
            font-weight: bold;
            border-radius: 38px;
            text-align: center;
            padding: 8px 20px;
            margin: 0 5px;
            cursor: pointer;

            &.filled{
                background-color: #541792;
                color: #fff;
            }
        }
    }
    .lang{
        .tit{
            cursor: pointer;
        }
    }
    .open-menu{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        padding: 15px;
        background-color: #f8f8f8;
    }
    .icon{
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
    }
}
@media screen and (max-width:768px) {
    #Header{
        padding: 15px;
        .toggle_wrap{
            top: 17px;
            left: 17px;
            transform: translateX(0);
        }
        .close_btn{
            margin-left: auto;
        }    
        .logo{
            height: 25px;
        }
        .open-menu #nav{
            display: flex;
            flex-direction: column;
        }
        #nav{
            li{
                font-size: 32px;
                padding-left: 0;
            }
        }
    }
}
</style>