import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/main',
    children:[
      {
        path: '/main',
        name: 'Main',
        component: () => import(/* webpackChunkName: "main" */ '../components/main/Main.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../components/about/About.vue')
      },
      {
        path: '/game',
        name: 'GameList',
        component: () => import(/* webpackChunkName: "game" */ '../components/game/GameList.vue')
      },
      {
        path: '/game/:name',
        name: 'GameDetail',
        component: () => import(/* webpackChunkName: "game" */ '../components/game/GameDetail.vue')
      },
      {
        path: '/launchpad',
        name: 'LaunchPadList',
        component: () => import(/* webpackChunkName: "game" */ '../components/launchpad/LaunchPadList.vue')
      },
      {
        path: '/launchpad/:name',
        name: 'LaunchPadDetail',
        component: () => import(/* webpackChunkName: "game" */ '../components/launchpad/LaunchPadDetail.vue')
      },
      {
        path: '/nft',
        name: 'NftList',
        component: () => import(/* webpackChunkName: "nft" */ '../components/nft/NftList.vue')
      },
      {
        path: '/nft/:name',
        name: 'NftDetail',
        component: () => import(/* webpackChunkName: "nft" */ '../components/nft/NftDetail.vue')
      },
      {
        path: '/mining',
        name: 'Mining',
        component: () => import(/* webpackChunkName: "game" */ '../components/nft/Mining.vue')
      },
      {
        path: '/nft-box',
        name: 'Box',
        component: () => import(/* webpackChunkName: "game" */ '../components/nft/Box.vue')
      },
      {
        path: '/minting',
        name: 'Minting',
        component: () => import(/* webpackChunkName: "game" */ '../components/nft/Minting.vue')
      },
      {
        path: '/farm',
        name: 'Farm',
        component: () => import(/* webpackChunkName: "game" */ '../components/farm/Farm.vue')
      },
      {
        path: '/community',
        name: 'Community',
        component: () => import(/* webpackChunkName: "community" */ '../components/community/Community.vue')
      },
      {
        path: '/friends',
        name: 'Friends',
        component: () => import(/* webpackChunkName: "friends" */ '../components/friends/Friends.vue')
      },
      {
        path: '/cs/notice',
        name: 'Notice',
        component: () => import(/* webpackChunkName: "cs" */ '../components/cs/Notice.vue')
      },
      {
        path: '/cs/notice/:idx',
        name: 'NoticeDetail',
        component: () => import(/* webpackChunkName: "cs" */ '../components/cs/NoticeDetail.vue')
      },
      {
        path: '/cs/faq',
        name: 'Faq',
        component: () => import(/* webpackChunkName: "cs" */ '../components/cs/Faq.vue')
      },
      {
        path: '/cs/inquiry',
        name: 'Inquiry',
        component: () => import(/* webpackChunkName: "cs" */ '../components/cs/Inquiry.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../components/about/About.vue')
      },
      {
        path: '/signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "about" */ '../components/sign/Signup.vue')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../components/sign/Login.vue')
      },
      {
        path: '/find-password',
        name: 'FindPassword',
        component: () => import(/* webpackChunkName: "about" */ '../components/sign/FindPassword.vue')
      },
      {
        path: '/:lang/certification',
        name: 'certification',
        component: () => import( /* webpackChunkName: "about" */ '../components/email/Certificate.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    return { x: 0, y: 0}
  }
})

export default router
