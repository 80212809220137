import Vue from 'vue'

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Popover from 'vue-js-popover'

import App from './App.vue'
import router from './router'
import store from './store'

require("dotenv").config();

Vue.use(BootstrapVue)

Vue.use(Popover)

Vue.config.productionTip = false

// axios
import $ from 'jquery'
import axios from "axios"
Vue.prototype.$http = axios
Vue.prototype.$jquery = $

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
