<template>
  <div :class="{'darkmode': $store.state.darkmode}">
    <Header />
    <div class="wrap">
      <Sidebar />
      <div class="dimmed" @click="openFn()"></div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>

    <!-- 지갑연결 로그인 리스트 -->
    <div class="popup_box" v-if="this.$store.state.walletLoginPp">
      <div class="dimm" @click="$store.commit('walletLoginPpFn', false)"></div>
      <div class="box">
        <div class="top">
          <h5>지갑 연결하기</h5>
          <i class="close_btn far fa-times" @click="$store.commit('walletLoginPpFn',false)"></i>
        </div>
        <ul class="login_list">
          <li v-for="(item,index) in walletLoginList" :key="index">
            <img :src="item.icon" :alt="item.name">
            {{item.name}}
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Sidebar from '@/components/common/Sidebar.vue'

export default {
  name: 'Home',
  components:{
    Header, Sidebar
  },
  data(){
    return{
      walletLoginList:[
        {
          name: "MetaMask",
          icon: require("@/assets/img/icon/MetamaskLogo.svg")
        },
        {
          name: "TrustWallet",
          icon: require("@/assets/img/icon/TrustWalletLogo.svg")
        },
        {
          name: "WalletConnect",
          icon: require("@/assets/img/icon/WalletConnectLogo.svg")
        },
        {
          name: "TokenPocket",
          icon: require("@/assets/img/icon/TokenPocketLogo.svg")
        },
        {
          name: "Coin98 Wallet",
          icon: require("@/assets/img/icon/Coin98Logo.svg")
        },
        {
          name: "MathWallet",
          icon: require("@/assets/img/icon/MathWalletLogo.svg")
        },
        {
          name: "Binance Chain Wallet",
          icon: require("@/assets/img/icon/BinanceChainWalletLogo.svg")
        },
        {
          name: "SafePal Wallet",
          icon: require("@/assets/img/icon/SafePalWalletLogo.svg")
        }
      ]
    }
  },
  methods:{
    openFn(){
        let openside = this.$store.state.aside;
        this.$store.commit('asideFn',!openside)
    }
  }
}
</script>
<style lang="scss" scoped>
  .popup_box{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 999;

    .dimm{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .box{
      position: absolute;
      background-color: #fff;
      min-width: 320px;
      max-width: 400px;
      padding: 0px 24px 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      border-radius: 10px;
    }

    .top{
      padding: 26px 0;
    }

    .close_btn{
      position: absolute;
      top: 30px;
      right: 20px;
    }
  }

  .login_list{
    li{
      padding: 10px;
      width: 100%;
      border: 1px solid #f4f4f4;
      box-sizing: border-box;
      display: flex;
      justify-content: start;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      border-radius: 10px;
      transition: all 0.2s;
      margin-bottom: 8px;

      img{
        margin-right: 20px;
      }

      &:hover{
        border-color: #541792;
      }
    }
  }
</style>